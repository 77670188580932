<template>
<div class="about-page w-full h-screen" id="element">
  <popupCharge :want-to-make-offer="charge"/>
  <header-back-recap :donnes="devis"/>
  <div class="hower mr-auto ml-auto">
    <recap :donnes="devis"/>

    <div class="mt-4 mb-2" v-if="expireDay <= 3 && expireDay >= -30">
      <buton label="Estimer la nouvelle prime" weight="500" background="#0269AD" @info="goProrogation"/>
    </div>
  </div>
<!--  <div class="details mr-auto ml-auto" v-if="activeDetail">-->
<!--    <details-souscription :donnes="assurance" @oga="retourDetail"/>-->
<!--  </div>-->

  {{donnee}}
</div>
</template>

<script>
import recap from '@/components/cedro/recapitulation/recapTemplate'
import headerBackRecap from '@/components/helper/headerBackRecap'
// import detailsSouscription from '@/components/cedro/souscription/detailsSouscription'
import popupCharge from '@/components/popup/popupCharge'
import buton from '@/components/helper/add/button'

export default {
  name: 'index',
  components: {
    recap,
    headerBackRecap,
    // detailsSouscription,
    popupCharge,
    buton
  },

  props: {
    width: {
      type: String,
      default: '30%'
    }
  },

  data () {
    return {
      devis: null,
      charge: false,
      expireDay: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {

    }
  },
  created () {
    this.devis = this.$store.getters.souscriptionDetail

    const date1 = new Date(this.devis.expireAt)
    const date2 = new Date()
    const dif = date2.getTime() - date1.getTime()
    this.expireDay = dif / (1000 * 3600 * 24)
    // console.log('expiration')
    // console.log(this.expireDay)
  },
  mounted () {
    this.smooth()
  },

  methods: {
    retourDetail (answer) {
      this.$router.push('/souscription')
    },

    goProrogation () {
      this.$router.push('/prolongement')
    },

    smooth () {
      const element = document.getElementById('element')
      // element.scrollIntoView(true)
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
  background-color: #F5F5F5;
  height: 100%;
  padding-bottom: 300px;
}
.hower{
  width: 65%;
  background-color: #fff;
  padding: 50px 230px 50px 230px;
  border-radius: 20px;
  margin-top: -100px;
}
.details{
  width: 65%;
  padding-top: 50px;
}
@media screen and (max-width: 700px){
  .hower{
    width: 93%;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    margin-top: -30px;
  }
}
</style>
